import React, { useState, useRef } from "react"
import { graphql, Link } from "gatsby"
import { Card } from "react-bootstrap"
import PopoverStickOnHover from "../components/PopoverStickOnHover"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Video from "../components/video"
import codeOfConduct from "../pdf/code-of-conduct-2023.pdf"
// import useLiveControl from "../controlled/useLiveControl"

// import { FacebookProvider, EmbeddedPost, Feed, Page  } from 'react-facebook';
// import popup
import MemberPopup from "../components/directorPopups/memberPopup"
import DirectorPopup from "../components/directorPopups/directorPopup"

// import images
import VisionUnsplash from "../images/absolutvision-unsplash.jpg"
import BakerUnsplash from "../images/kaitlyn-baker-unsplash.jpg"

const About = ({ data }) => {
  const content = data.wpgraphql.page
  const [visible, setVisible] = useState(false)
  const [popType, setPopType] = useState("")
  const [popData, setPopData] = useState("")
  const handstick = useRef(null)

  const showbenefitpopup = (param, type) => event => {
    event.preventDefault()
    setPopData(param)
    setVisible(true)
    setPopType(type)
  }

  const hidebenefitpopup = () => {
    setVisible(false)
  }

  const svgCodes = [
    {
      id: "charles-knadler",
      data1:
        "M958.333 140.667 977 114l42.667-20L1061 104.667l20 36 6.667 38.667-6.667 40-6.667 46.666 6.667 17.333 37 19.767 52.333 26.9 37.333 154.667L831 475l6-37 42.667-114.667 97.333-40v-52l-12-12-13-32.033Z",
      data2:
        "m1019.765 95.058-42.099 19.734-18.374 26.25-6.265 46.125 12.823 31.603L978 230.92v53.084l-97.549 40.088-42.48 114.165-5.8 35.773 374.217 9.604-36.92-152.954-51.94-26.694-37.328-19.946-6.893-17.924 6.707-46.947 6.638-39.833-6.609-38.332-19.709-35.476-40.569-10.47m-.098-1.058L1061 104.667l20 36 6.667 38.666-6.667 40-6.667 46.667 6.667 17.333 37 19.771L1170.333 330l37.334 154.667L831 475l6-37 42.667-114.667 97.333-40v-52l-12-12-13-32.037 6.333-46.63L977 114l42.667-20Z",
    },
    {
      id: "kristin-dittmer",
      data1:
        "m597 170 36-25.333L673 150l29.333 33.333L714 222l20.333 78.667v44l21.333 26.667L777 442v30.333H497L505 410l8-38.667 14.667-26.667 40.122-10.72L569.9 235.2 577 202Z",
      data2:
        "M633.256 145.71 597.74 170.7l-19.8 31.68-7.042 32.931-2.127 99.407-40.435 10.804-14.388 26.16-7.962 38.482-7.85 61.168H776v-29.185l-21.235-70.34-21.432-26.79v-44.224l-20.296-78.524-11.598-38.44-28.942-32.888-39.241-5.232m-.256-1.043L673 150l29.333 33.333L714 222l20.333 78.667v44l21.334 26.666L777 442v30.333H497L505 410l8-38.667 14.667-26.666 40.121-10.72 2.113-98.751L577 202l20-32 36-25.333Z",
    },
    {
      id: "rob-palmer",
      data1:
        "M1166.333 148.667 1189 131.333l30.667-8L1258.333 142l12.7 54.844v38.49l-12.7 20 12.7 28L1330 308.667 1300 483h-88l-15-93-30.667-69.333-34.667-21.333L1160 283.333l17-28-17-20.984v-37.505Z",
      data2:
        "m1219.563 124.394-30.116 7.856-22.176 16.959-6.271 47.7v37.086l17.22 21.256-17.5 28.825-.228.128-26.857 15.166 33.487 20.608 30.845 69.735.02.128L1212.852 482h86.305l29.724-172.726-58.594-25.175-13.088-28.845 12.838-20.211v-38.085l-12.57-54.266-37.904-18.298m.104-1.06L1258.334 142l12.703 54.843v38.49l-12.703 20 12.703 28L1330 308.667 1300 483h-88l-15-93-30.666-69.333-34.667-21.334 28.333-16 17-28-17-20.984v-37.506l6.334-48.176L1189 131.333l30.667-8Z",
    },
    {
      id: "blain-bagley",
      data1:
        "m299.667 288.667 70.667-28 25.333-32-10.667-24-6.667-24 6.667-52L418.5 100h48.811L497 118l17.333 72L497 218l-9.333 18.667 26.667 32 45.333 14.667v41.333l-29.979 7.144L514.333 346 497 384.667 487.667 470h-218v-77.333L285 339.187Z",
      data2:
        "m486.77 469 9.253-84.599.064-.143 17.417-38.854 15.697-14.506.255-.06 29.21-6.961v-39.816l-44.916-14.532-27.27-32.725 9.646-19.292.024-.038 17.113-27.646-17.138-71.189L467.031 101H418.87l-32.926 28.176-6.592 51.418 6.593 23.734 10.882 24.485-25.875 32.685-.25.098-70.21 27.82-14.53 50.047-15.294 53.344V469H486.77m.897 1h-218v-77.333L285 339.187l14.667-50.52 70.666-28 25.334-32-10.667-24-6.667-24 6.667-52L418.5 100h48.81L497 118l17.333 72L497 218l-9.333 18.667 26.666 32 45.334 14.666v41.334l-29.98 7.144L514.333 346 497 384.667 487.667 470Z",
    },

    {
      id: "joel-cannon",
      data1:
        "m799.667 41 36-12L865 34.333 894.333 61 901 99.667l-2.431 41.217L887.667 169l8.154 18.473 75.846 44.194V273l-29.333 22.667L873 318.333l-8 33.333L749 345l-26.667-113.333L781.361 205 789 178.333l-8.585-35.875L779.667 93 789 55.667Z",
      data2:
        "m835.74 30.03-35.453 11.817-10.364 14.25-9.254 37.019.744 49.216 8.62 36.024-7.838 27.365-.422.19-58.278 26.328 26.307 111.805 114.42 6.576 7.936-33.064 69.7-22.786 28.809-22.261v-40.268l-75.63-44.067-8.452-19.15 10.995-28.356 2.415-40.945-6.589-38.214-28.867-26.243-28.8-5.236m-.072-1.03L865 34.333 894.333 61 901 99.667l-2.431 41.217L887.667 169l8.154 18.473 75.846 44.194V273l-29.334 22.667L873 318.333l-8 33.334L749 345l-26.667-113.333L781.361 205 789 178.333l-8.585-35.875L779.667 93 789 55.667 799.667 41l36-12Z",
    },

    {
      id: "allyson-torsak",
      data1:
        "m1365 128.667 25.333-16h37.334l42.667 28 24 66.667V242L1513 275.333l53.333 28L1581 428.667V486l-276-6 22.667-127.333L1356 275.333l-19-48V178Z",
      data2:
        "m1390.623 113.667-24.877 15.711L1338 178.264v48.879l19.07 48.177-.131.357-28.303 77.251-22.447 126.098L1580 484.978v-56.253l-14.598-124.751-53.13-27.893-.144-.26-18.795-33.56v-34.753l-23.827-66.189-42.138-27.652h-36.745m-.29-1h37.333l42.667 28 24 66.666V242L1513 275.333l53.333 28L1581 428.667V486l-276-6 22.667-127.333L1356 275.333l-19-48V178l28-49.333 25.333-16Z",
    },
  ]

  // const { isStaging } = useLiveControl()

  return (
    <Layout>
      <Seo
        title={"Offer Financing to Your Customers"}
        description={
          "EnerBank is a specialized bank providing home improvement loans to contractors' homeowner customers. Learn how offering financing can help grow your business."
        }
      />
      <Helmet>
        <body className="about" />
      </Helmet>
      {/* start banner Area */}
      <section className="container my-5 pt-4" id="companyoverview">
        <div className="row d-flex align-items-end">
          <div
            className="col-md-11 m-auto"
            dangerouslySetInnerHTML={{ __html: content.content }}
          />
        </div>
      </section>
      {/* Video Area */}
      {content.video_section.video.videoUrl && (
        <section className="video-section mb-5">
          <div className="container">
            <div className="row d-flex align-items-end">
              <div className="col-md-12 m-auto embed-container" ref={handstick}>
                {" "}
                {/*  onClick={this.handleClick} */}
                <Video
                  videoSrcURL={content.video_section.video.videoUrl}
                  videoTitle="CEO Intro For About Us Page"
                  videoWidth="100%"
                  videoHeight="500"
                />
              </div>
            </div>
          </div>
        </section>
      )}
      {/* enerbank leadership */}
      <div
        id="leadership"
        className="position-relative d-none pt-2 pb-4"
      >
        <div
          className="position-absolute w-100 pb-2 leadership-heading px-3"
          style={{ top: 0 }}
          dangerouslySetInnerHTML={{
            __html: content.about.enerbankLeadership.leadershipHeading,
          }}
        />
        <img
          src={content.about.enerbankLeadership.leadershipBanner.sourceUrl}
          alt="Leaders Banner"
          className="w-100 d-block executive-photo"
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1903 1269"
          className="position-absolute"
          style={{ top: "6%", zIndex: 99 }}
        >
          <g fill="transparent">
            <g stroke="#707070" strokeWidth="0">
              <path stroke="none" d="M0 0h1903v1269H0z" />
              <path d="M.5.5h1902v1268H.5z" />
            </g>
            {content.about.boardOfDirectors.directors &&
              content.about.boardOfDirectors.directors.map((item, i) => {
                return (
                  <PopoverStickOnHover
                    component={
                      <Card
                        className="py-2 px-3"
                        style={{ background: "rgba(255, 255, 255, 0.85)" }}
                      >
                        <h3
                          className="text-blue pb-2 mb-1"
                          style={{
                            borderBottom: "2px solid #0077C8",
                            cursor: "pointer",
                          }}
                          onClick={showbenefitpopup(item, "director")}
                        >
                          {item.directorName}
                        </h3>
                        <p className="text-sm mb-0 mt-2">
                          {item.directorPosition}
                        </p>
                        <button
                          className="font-weight-bold mt-auto text-left py-1 px-0 mx-0"
                          style={{
                            cursor: "pointer",
                            color: "#0077C8",
                            fontSize: "13px",
                            border: "none",
                            background: "none",
                          }}
                          onClick={showbenefitpopup(item, "director")}
                        >
                          Click to Read More
                        </button>
                      </Card>
                    }
                    placement={"bottom"}
                    key={item.fieldGroupName + i}
                  >
                    <path
                      key={item.fieldGroupName + i + "shell"}
                      data-name={"Path " + i}
                      d={svgCodes[i].data1}
                      fill="transparent"
                      stroke="#707070"
                      strokeWidth="0"
                      onClick={showbenefitpopup(item, "director")}
                    />
                    <path
                      key={item.fieldGroupName + i}
                      id={svgCodes[i].id}
                      data-name={"Path " + i}
                      d={svgCodes[i].data2}
                      fill="transparent"
                      stroke="#707070"
                      strokeWidth="0"
                      onClick={showbenefitpopup(item, "director")}
                    />
                  </PopoverStickOnHover>
                )
              })}
          </g>
        </svg>
      </div>

      <div className="container" id="leadership">
        <div className="row mt-5">
          <div
            className="w-100 pb-3 leadership-heading px-3 mt-5 mb-4"
            dangerouslySetInnerHTML={{
              __html: content.about.enerbankLeadership.leadershipHeading,
            }}
          />
          {content.about.boardOfDirectors.directors &&
            content.about.boardOfDirectors.directors.map(
              (item, i) =>
                i < 6 && (
                  <div
                    className="col-sm-6 col-md-6 col-lg-4"
                    key={"leadership" + i}
                  >
                    <div
                      className="mb-4 text-center director-col"
                      onClick={showbenefitpopup(item, "director")}
                    >
                      <div className="position-relative mb-2">
                        <img
                          src={item.directorThumb.sourceUrl}
                          alt={item.directorThumb.altText}
                        />
                        <div
                          className="director-profile"
                          dangerouslySetInnerHTML={{
                            __html: item.directorOverlay,
                          }}
                        />
                      </div>
                      <h3 className="directorName text-blue mb-0">
                        {item.directorName}
                      </h3>
                      <p>{item.directorPosition}</p>
                    </div>
                  </div>
                )
            )}
        </div>
      </div>
      {/* {content.about.newestmember.directors &&
        content.about.newestmember.directors.map((item, i) => {
          return (
            <section
              className="bg-blue text-center mt-3 mt-lg-n4"
              key={"newestmember" + i}
            >
              <div className="container">
                <div className="d-sm-flex py-5 text-white mb-5 mb-sm-0">
                  <img
                    onClick={showbenefitpopup(item, "director")}
                    className="mr-sm-5 mb-5 mb-sm-0 newestmember"
                    alt="Fdic logo"
                    src={item.directorThumb.sourceUrl}
                    data-holder-rendered="true"
                  />
                  <div className="text-left px-0 px-sm-0">
                    <h2
                      onClick={showbenefitpopup(item, "director")}
                      className="h2 text-white"
                    >
                      Meet the newest member of our senior team:
                    </h2>
                    <h3 className="h3 text-white">{item.directorName}</h3>
                    <p className="font-italic">{item.directorPosition}</p>
                    <a
                      href="#"
                      onClick={showbenefitpopup(item, "director")}
                      className="font-weight-bold btn genric-btn primary"
                    >
                      Click to Read More
                    </a>
                  </div>
                </div>
              </div>
            </section>
          )
        })} */}

      {/* {status === "ready" && (
                <div>
                Script function call response: <b>{TEST_SCRIPT.start()}</b>
                </div>
            )} */}
      {/* Community Involvement */}
      <section
        className="container mt-5 my-md-5 my-lg-5 communityinvolvement"
        id="communityinvolvement"
      >
        <h2 className="text-center mb-4">
          {content.about.communityInvolvement.ciHeading}
        </h2>
        <p>{content.about.communityInvolvement.ciBrif}</p>
        <div className="row my-5">
          <div className="gridgallery col-lg-12">
            {content.about.communityInvolvement.ciLogos.map((item, i) => {
              return (
                <a
                  href={item.links}
                  target="_blank"
                  rel="noreferrer noopener"
                  key={"communityinvolvement" + i}
                >
                  <img
                    alt={item.logo.altText}
                    className="d-block mx-auto"
                    src={item.logo.sourceUrl}
                    data-holder-rendered="true"
                  />
                </a>
              )
              // if (i === 0) {
              //     return (
              //         <div className="horizontal" key={'communityinvolvement' + i}>
              //             <a href={item.links} target="_blank" rel="noreferrer">
              //                 <img alt="PHP" className="d-block mx-auto community-logo" src={item.logo.sourceUrl} data-holder-rendered="true" />
              //             </a>
              //         </div>
              //     )
              // } else {
              //     return (
              //         <div className="verticle" key={'communityinvolvement' + i}>
              //             <a href={item.links} target="_blank" rel="noreferrer">
              //                 <img alt="PHP" className="d-block mx-auto community-logo" src={item.logo.sourceUrl} data-holder-rendered="true" />
              //             </a>
              //         </div>
              //     )
              // }
            })}
          </div>
        </div>
      </section>

      <section className="bg-blue my-5" id="memberfdic">
        <div className="container">
          <div className="d-sm-flex py-5 text-white mb-5 mb-sm-0">
            <div className="pr-sm-5 d-flex align-center">
              <img
                className="mr-sm-5 mb-5 mb-sm-0 newestmember"
                alt={content.about.memberFdic.fdicLogo.altText}
                src={content.about.memberFdic.fdicLogo.sourceUrl}
                data-holder-rendered="true"
              />
            </div>
            <div
              className="text-left px-0 px-sm-0"
              dangerouslySetInnerHTML={{
                __html: content.about.memberFdic.fdicDescription,
              }}
            />
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row">
          <div
            className="col-12 col-md-12 m-auto"
            dangerouslySetInnerHTML={{ __html: content.about.cmsEnergy }}
          />
          {/* <div id="boardofdirectors" className="col-12 col-md-10 m-auto" dangerouslySetInnerHTML={{ __html: content.about.teamMembers.boardBrif }} /> */}
        </div>
        {/*
                <section className="row mt-5">
                    {content.about.teamMembers.members && (
                        content.about.teamMembers.members.map((item, i) => {
                            return (
                                <div className="col-sm-6 col-md-6 col-lg-4" key={'teamMembers' + i}>
                                    <div className="mb-4 text-center director-col" onClick={showbenefitpopup(item, 'member')}>
                                        <div className="position-relative mb-3">
                                            <img src={item.memberThumb.sourceUrl} alt={item.memberThumb.altText} />
                                            <div className="director-profile" dangerouslySetInnerHTML={{ __html: item.memberOverlay }} />
                                        </div>
                                        <p className="directorName">{item.memberName}</p>
                                    </div>
                                </div>
                            )
                        })
                    )}
                </section> */}

        {/* Start News & Media Area */}
        <section className="my-5">
          {/* <div className="container"> */}
          <div className="d-flex flex-wrap">
            <div className="card box-shadow bg-white rounded text-center col-xs-12 col-md mb-3 mb-lg-0 p-0 mr-md-3 mr-lg-5">
              <div className="wygthumb news_media">
                <img
                  src={VisionUnsplash}
                  className="card-img-top"
                  alt="absolutvision unsplash"
                />
              </div>
              <div className="pb-0 px-4 d-flex card-body">
                <h3 className="mb-3 mt-2">News / Media</h3>
                <p>
                  See the latest corporate news about EnerBank USA and related
                  topics
                </p>
              </div>
              {/* <a href="http://news.enerbank.com/" rel="noopener noreferrer" className="pb-4 px-4 font-weight-bold mt-auto">Click here to Read More</a> */}
              <Link
                to={"/news/"}
                className="pb-4 px-4 font-weight-bold mt-auto"
              >
                Click here to Read More
              </Link>
            </div>
            <div className="card box-shadow bg-white rounded text-center col-xs-12 col-md mb-3 mb-lg-0 p-0 ml-md-3 ml-lg-5">
              <div className="wygthumb blog">
                <img
                  src={BakerUnsplash}
                  className="card-img-top"
                  alt="kaitlyn baker unsplash"
                />
              </div>
              <div className="pb-0 px-4 card-body">
                <h3 className="mb-3 mt-2">EnerBank Blog</h3>
                <p>
                  Get best practices, tips, and other useful information and
                  perspectives on home improvement and payment options from the
                </p>
              </div>
              <Link
                to={"/blog/"}
                className="pb-4 px-4 font-weight-bold mt-auto"
              >
                Read EnerBank USA Blog
              </Link>
            </div>
          </div>
          {/* </div> */}
        </section>
        {/* End News & Media Area */}
      </section>

      <section className="container-fluid pb-5">
        <div className="row">
          <style
            dangerouslySetInnerHTML={{
              __html: [
                ".codeofconduct::before{",
                "background-image: url(" +
                  content.about.codeOfConduct.banner.sourceUrl +
                  ")",
                "}",
              ].join("\n"),
            }}
          />
          <div
            className="col-md-6 offset-md-6 codeofconduct bg-blue p-5 mt-5 mb-4"
            id="codeofconduct"
          >
            <h2 className="h3 text-white mb-4 pb-2">
              <a
                href="https://s202.q4cdn.com/476346942/files/doc_downloads/governance-documents/corporate-documents/Regions-Code-of-Business-Conduct-and-Ethics.pdf"
                target="_blank"
                rel="noreferrer noopener"
                className="border py-2 px-3 rounded"
              >
                {content.about.codeOfConduct.title}
              </a>
            </h2>
            <p>{content.about.codeOfConduct.description}</p>
            <a
              href={codeOfConduct}
              target="_blank"
              rel="noreferrer noopener"
              className="py-2 rounded"
            >
              Read More ...
            </a>
          </div>
        </div>

        <div className="pt-4 text-center mb-3">
          <p>Want to Learn More?</p>
          <Link
            to={"/sign-up/"}
            className="btn bg-blue d-inline-block text-white mx-auto px-5"
          >
            Contact Us
          </Link>
        </div>

        {/* <FacebookProvider appId="905967340180603">
                    <EmbeddedPost href="https://www.facebook.com/lambentstudios/posts/3698162980273637" width="500" />
                    <EmbeddedPost href="https://www.facebook.com/lambentstudios/posts/3692089910880944" width="500" />
                    <Page href="https://www.facebook.com/lambentstudios" tabs="timeline" />
                </FacebookProvider> */}
      </section>
      {visible && popType === "member" && (
        <MemberPopup
          visiblity={visible}
          handleClose={hidebenefitpopup}
          popData={popData}
        />
      )}
      {visible && popType === "director" && (
        <DirectorPopup
          visiblity={visible}
          handleClose={hidebenefitpopup}
          popData={popData}
        />
      )}
    </Layout>
  )
}

export default About
export const query = graphql`
  query($databaseId: ID!) {
    wpgraphql {
      page(id: $databaseId, idType: DATABASE_ID) {
        title
        date
        content(format: RENDERED)
        featuredImage {
          node {
            altText
            title(format: RENDERED)
            mediaItemUrl
            slug
          }
        }
        video_section {
          video {
            videoUrl
          }
        }
        about {
          fieldGroupName
          memberFdic {
            fdicDescription
            fieldGroupName
            fdicLogo {
              sourceUrl
              altText
            }
          }
          enerbankLeadership {
            fieldGroupName
            leadershipHeading
            leadershipBanner {
              sourceUrl
              altText
            }
          }
          cmsEnergy
          communityInvolvement {
            ciBrif
            ciHeading
            ciLogos {
              fieldGroupName
              links
              logo {
                sourceUrl
                altText
              }
            }
          }
          boardOfDirectors {
            boardBrif
            fieldGroupName
            directors {
              directorDescription
              directorName
              directorOverlay
              directorPosition
              fieldGroupName
              directorThumb {
                sourceUrl
                altText
              }
            }
          }
          teamMembers {
            boardBrif
            fieldGroupName
            members {
              directorPosition
              fieldGroupName
              memberDescription
              memberName
              memberOverlay
              memberThumb {
                sourceUrl
              }
            }
          }
          codeOfConduct {
            description
            fieldGroupName
            title
            banner {
              sourceUrl
              altText
              link
            }
          }
          newestmember {
            fieldGroupName
            directors {
              directorDescription
              directorName
              directorOverlay
              directorPosition
              directorThumb {
                sourceUrl
              }
              fieldGroupName
            }
          }
        }
      }
    }
  }
`
